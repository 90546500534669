import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Badge from 'react-bootstrap/Badge';
import { useSearchParams } from 'react-router-dom'

export default function MyNavBar({getStockData, setTicker}) {
    const [searchParams, setSearchParams] = useSearchParams({search: ''})
    const [formValue, setFormValue] = useState({ search: searchParams.get("search") });

    useEffect(() => {
        const ticker =  searchParams.get('search').trim().toUpperCase();
        if(ticker === ""){
            return;
        }

        setFormValue({search: ticker});
        getStockData(ticker);
        setTicker(ticker);
    }, [searchParams]);

    const handleValidation = (event) => {
        const { name, value } = event.target;

        setFormValue((prevFormValue) => ({
          ...prevFormValue,
          [name]: value,
        }));
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        let ticker = formValue.search.toUpperCase();
        if(ticker.trim() === ""){
            return;
        }

        //setSearchParams({search: ticker});
        window.open("/?search="+ticker, '_self', 'noopener,noreferrer');
    }

    return (
        <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme="dark">
           <Container>
             <Image width="35" src="logo.png"/>
             <Navbar.Brand href="/">&nbsp;<Badge bg="dark" text="light"><b>Trader</b></Badge></Navbar.Brand>
             <Navbar.Toggle aria-controls="basic-navbar-nav" />
             <Navbar.Collapse id="basic-navbar-nav">
               <Nav className="me-auto">
                 <Nav.Link href="/">Home</Nav.Link>
                 <Nav.Link href="/screener">Screener</Nav.Link>
                 <Nav.Link href="/calendar">Calendar</Nav.Link>
                 <Nav.Link href="/earnings">Earnings</Nav.Link>
                 <Nav.Link href="/news">News</Nav.Link>
                 <Nav.Link href="/economic-data">Economic Data</Nav.Link>
                 <Nav.Link href="/market-holidays">Market Holidays</Nav.Link>
                 <NavDropdown title="Tools" id="tools-nav-dropdown">
                    <NavDropdown.Item href="/options">
                      Options
                    </NavDropdown.Item>
                    <NavDropdown.Item href="https://www.tradingview.com/heatmap/stock" target="_blank">
                      HeatMap
                    </NavDropdown.Item>
                   <NavDropdown.Item href="https://sslecal2.investing.com?columns=exc_flags,exc_currency,exc_importance,exc_actual,exc_forecast,exc_previous&importance=2,3&features=datepicker,timezone&countries=5&calType=week&timeZone=55&lang=1" target="_blank">
                     Economic Calendar
                   </NavDropdown.Item>
                   <NavDropdown.Item href="https://www.barchart.com/investing-ideas/insider-trading-activity" target="_blank">
                     Insider Trading Activity
                   </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/bear-bull-sentiment">
                      Bear vs Bull (SeeSaw)
                    </NavDropdown.Item>
                  </NavDropdown>
               </Nav>
               <Nav>

                     <Nav.Link href="/about">About Us</Nav.Link>
                     &nbsp;&nbsp;&nbsp;
               </Nav>
              <Form className="d-flex" onSubmit={handleSearch}>
                <Form.Control
                  type="search"
                  name="search"
                  onChange={handleValidation}
                  value={formValue.search}
                  placeholder="Symbol..."
                  style={{textTransform: 'uppercase'}}
                  className="me-2"
                  aria-label="Search"
                />
                <Button variant="dark" type="submit">Search</Button>
              </Form>
             </Navbar.Collapse>
           </Container>
         </Navbar>
    );
}