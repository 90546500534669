import React from "react";
import { Pie } from "react-chartjs-2";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const SuperInvestorChart = ({ data }) => {
    const chartData = {
        labels: ["Buy", "Add", "Unchanged", "Reduce", "Sell"],
        datasets: [
            {
                label: "Activity Distribution",
                data: [
                    data.recentActivityToCount.BUY,
                    data.recentActivityToCount.ADD,
                    data.recentActivityToCount.UNCHANGED,
                    data.recentActivityToCount.REDUCE,
                    data.recentActivityToCount.SELL,
                ],
                backgroundColor: ["#14A44D", "#62aa3c", "#5999f6", "#FF3C3C", "#FF0000"],
                hoverBackgroundColor: ["#0F7C3A", "#4C852F", "#3F72C2", "#CC3030", "#CC0000"],
            },
        ],
    };

    const options = {
        responsive: true,
        aspectRatio: 1.5,
        plugins: {
            legend: {
                position: "right",
                align: "center",
                labels: {
                    boxWidth: 20,
                    padding: 10,
                },
            },
        },
    };

    return (
        <div>
            <h5>Activity Distribution</h5>
            <Pie data={chartData} options={options} />
        </div>
    );
};

export default SuperInvestorChart;
