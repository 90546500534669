import OptionsChainChart from './OptionsChainChart';

export default function Options() {
    return (
        <>
            QQQ:
            <OptionsChainChart ticker="QQQ" />

            SPY:
            <OptionsChainChart ticker="SPY" />
        </>
    );
}