import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const OptionsChainChart = ({ticker}) => {
  const [chartData, setChartData] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(null);

   const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Open Interest by Strike Price',
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Strike Price',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Open Interest',
            },
          },
        },
      };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_OPTIONS_CHAIN_URL.replace("{ticker}", ticker)}`);
        const data = response.data;

        // Extract necessary details
        const options = data.options;
        const price = data.price;

        const strikes = Array.from(new Set(options.map(option => option.strike))); // Unique strikes
        const callData = strikes.map(strike =>
          options.find(o => o.strike === strike && o.type === 'call')?.openInterest || 0
        );
        const putData = strikes.map(strike =>
          options.find(o => o.strike === strike && o.type === 'put')?.openInterest || 0
        );

        // Set state for chart
        setChartData({
          labels: strikes,
          datasets: [
            {
              label: 'Calls',
              data: callData,
              backgroundColor: 'aqua',
            },
            {
              label: 'Puts',
              data: putData,
              backgroundColor: 'orange',
            },
          ],
        });

        // Set current price for blue dotted bar
        setCurrentPrice(price);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

return (
    <div>
        {chartData?.labels?.length ? (
          <div style={{ position: 'relative' }}>
            <Bar data={chartData} options={options} />
            {currentPrice && (
              <>
              <svg width="10px" height="75%" style={{
                position: 'absolute',
                bottom: '60px',
                left: `${((chartData.labels.findIndex(strike => Math.abs(strike - currentPrice) === Math.min(...chartData.labels.map(s => Math.abs(s - currentPrice)))) / (chartData.labels.length - 1)) + 0.02) * 100}%`,
                stroke: 'white',
                strokeWidth: 2,
                strokeDasharray: '2,2'
              }}>
                <line x1="7.5" y1="0" x2="7.5" y2="100%" />
              </svg>
              <span style={{
                 position: 'absolute',
                 top: '12%',
                 fontSize: '11px',
                 left: `${((chartData.labels.findIndex(strike => Math.abs(strike - currentPrice) === Math.min(...chartData.labels.map(s => Math.abs(s - currentPrice)))) / (chartData.labels.length - 1)) + 0.00) * 100}%`,
              }}>Price: {currentPrice}</span>
              </>
            )}
          </div>
        ) : (
          chartData === null ? (
            <p>Loading...</p>
          ) : (<span>&nbsp;</span>)
        )}
      </div>
  );
};

export default OptionsChainChart;
