import React from "react";
import Table from 'react-bootstrap/Table';

const SuperInvestorTable = ({ data }) => {
    const activityColors = {
        Buy: "#14A44D",
        Add: "#62aa3c",
        Unchanged: "#ffffff",
        Reduce: "#FF3C3C",
        Sell: "#FF0000",
    };

    return (
        <>
            <h4>Super Investor Activities</h4>
            <Table striped bordered hover size="sm" variant="dark" responsive="md">
                <thead>
                    <tr>
                        <th>Portfolio Manager</th>
                        <th>Activity</th>
                        <th>Share Count</th>
                    </tr>
                </thead>
                <tbody>
                    {data.recentActivities.map((activity, index) => (
                        <tr key={index}>
                            <td><small>{activity.portfolioManager}</small></td>
                            <td style={{ color: activityColors[activity.activity.split(" ")[0]] || "#FFFFFF" }}>
                                {activity.activity}
                            </td>
                            <td><small className="text-muted">{activity.shareCount.toLocaleString()}</small></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
};

export default SuperInvestorTable;
